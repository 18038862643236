import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Table, Tag, Space, Button, Typography, Drawer } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import "./CallLog.css";
import useAxios from "./../../CustomHooks/useAxios";
import TicketNoteOutEditByTKID from "./../TicketNoteOutEditByTKID";
import TicketNoteNewFromCallLog from "./../TicketNoteNewFromCallLog";

import AddNewContact from "./../Customers/Contacts/Add";
import EditContact from "./../Customers/Contacts/Edit";
import SwitchCustomer from "./../Customers/Contacts/SelectaContact";

import CallTo from "./../CallTo";
// import ShowCallLogTable from './ShowCallLogTable';
// import PageLink from './../PageLink/PageLink';
const dateFormat = "YYYY-MM-DD HH:mm:ss";

function ShowCallLogTable({ data, setRefressSearch, sttBegin,sapxep,setSapxep }) {
  let stt=(sttBegin)?sttBegin:1;
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  const refAudio = useRef();
  const [visibleNewContact, setVisibleNewContact] = useState(false);
  const [visibleEditContact, setVisibleEditContact] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleNewTicket, setVisibleNewTicket] = useState(false);

  const [visibleSwitch, setVisibleSwitch] = useState(false);
  const [customerid, setCustomerid] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);
  const [displayAudio, setDisplayAudio] = useState("none");

  const getDepartmentName = (department_id) => {
    return user?.deps[department_id];
  };
  const handleEditTicket = (item) => {
    console.log("[handleEditTicket]:item=", item);
    setSelectedItem(item);
    if (item.tkid) {
      setVisibleEdit(true);
    } else {
      setVisibleNewTicket(true);
    }
  };
  const getCustomerInfo = async (id) => {
    try {
      const kq = await axiosi.get("comcontacts/id", { params: { id } });
      console.log("[getCustomerInfo]:kq.data=", kq.data);
      return {
        error: 0,
        message: "OK",
        data: kq.data.rows,
      };
    } catch (err) {
      console.log("ERROR");
      return {
        error: 1,
        message: "Co' loi",
      };
    }
  };
  const getCustomerInfoByTel = async (tel) => {
    try {
      const kq = await axiosi.get("comcontacts/tel", { params: { tel } });
      const kqdata = await kq.data;
      // console.log("[getCustomerInfoByTel]:kqdata=",kqdata);
      // console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);

      if (kqdata.rows.length === 1) {
        setContactInfo(kqdata.rows[0]);
        setVisibleEditContact(true);

        // setCustomerInfo(kqdata.rows);
        // setCustomerId(kqdata.rows[0]['id']);
        // setPriority_level(` (Priority: ${kqdata.rows[0].priority_level})`);
      } else if (kqdata.rows.length > 1) {
        setVisibleSwitch(true);
        // setCustomerInfo(null);
        // setCustomerId('');
        // setPriority_level("");
      } else {
        setVisibleNewContact(true);
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data=", error.response.data);
        console.log("error.response.status=", error.response.status);
        console.log("error.response.headers=", error.response.headers);
        // message.error(`Error: ${error.response.data.code}`);
        setContactInfo(null);
        // setPriority_level("");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        setContactInfo(null);
        // setPriority_level("");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        setContactInfo(null);
        // setPriority_level("");
      }
      console.log(error.config);
      setContactInfo(null);
      // setPriority_level("");
    }
  };
  const handleCustomer = async (item) => {
    setSelectedItem(item);
    const kq = await getCustomerInfo(item.customer_id);
    console.log("[handleCustomer]:kq=", kq);
    if (kq.message === "OK") {
      if (kq.data.length > 0) {
        console.log("[handleEdit]:kq.data.length>0");
        setContactInfo(kq.data[0]);
        setVisibleEditContact(true);
      } else {
        console.log("[handleEdit]:kq.data.length<=0");
        // message("this customer is deleted, so please add a new customer !")
        // setVisibleNewContact(true);
        getCustomerInfoByTel(item.tel);
      }
    } else {
      // message("[handleEdit]:There are some thing wrong !");
    }
  };
  const handleMakeCall = (callto) => {
    makeCall(callto);
  };
  const handlePlayAudio = async (id, uniqueid) => {
    // refAudio.current.pause();
    setDisplayAudio("block");
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/fileLink?fileLink=${kq.data.fileLink}`
    refAudio.current.src = `${process.env.REACT_APP_DOMAIN}/calllogs/fileLink?fileLink=${kq.data.fileLink}`;
    refAudio.current.play();
  };

  const handleDownloadAudio = async (id, uniqueid, pbxtel, tel, ext) => {
    // refAudio.current.pause();
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    //const linkfile=`https://gcall.com.vn:2943/calllogs/fileLink?fileLink=${kq.data.fileLink}`
    const linkfile = `${process.env.REACT_APP_DOMAIN}/calllogs/fileLink?fileLink=${kq.data.fileLink}`;
    // window.open(linkfile);
    axios({
      url: linkfile, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${uniqueid}_${pbxtel}_${tel}_${ext}.wav`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const formatTime = (second) => {
    // return("second");

    const b = 86400;
    // second += b*22;
    if (second >= b) {
      let giay = second % b;
      let ngay = (second - giay) / b;
      //new Date(3600*24 * 1000).toISOString().substr(11, 8)
      return (
        `${ngay}d.` + new Date(second * 1000).toISOString().substring(11, 19)
      );
      //${moment(giay).format("HH:mm:ss")}`;
      //+ new Date(giay * 1000).toISOString().substr(11, 8);
      //${moment(giay).format(dateFormat)}`;
    } else {
      return new Date(second * 1000).toISOString().substring(11, 19); //
      // return `${moment(12).format("HH:mm:ss")}`;
    }
    //return `${moment(second).format(dateFormat)}`;
    //new Date(giay * 1000).toISOString().substr(11, 8);//
  };
  const onCloseAddAddContact = () => {
    setVisibleNewContact(false);
  };
  const onCloseEditContact = () => {
    setVisibleEditContact(false);
  };
  const onCloseSwitch = () => {
    setVisibleSwitch(false);
  };
  const RenderCallInfo = (item) => {
    const connected = item.connected ? "connected" : "notconnected";

    if (item.vm === "true") {
      return <MailOutlined className="connected" title="Voice mail" />;
    } else if (item.calldirection === "in") {
      return (
        <div
          className={`iconCallOut callin ${connected}`}
          title={`Call in, ${connected}`}
        >
          <PhoneOutlined className={"icon2 icon21"} />
          <ArrowDownOutlined className={"icon2 icon22"} />
        </div>
      );
    } else if (item.calldirection === "out") {
      return (
        <div
          className={`iconCallOut callout ${connected}`}
          title={`Call out, ${connected}`}
        >
          <PhoneOutlined className={"icon2 icon21"} />
          <ArrowUpOutlined className={"icon2 icon22"} />
        </div>
      );
    } else {
      return (
        <PhoneOutlined className={`${connected}`} title={`pbx, ${connected}`} />
      );
    }
  };

  const RenderCallStatus = (item) => {
    //item.dialstatus
    if (
      item.connected === 0 &&
      item.calldirection === "out" &&
      item.dialstatus !== ""
    ) {
      return `[${item.dialstatus}]`;
    } else return <span></span>;
  };
  const getDropCause = async (uniqueid) => {
    try {
      const kq = await axiosi.get("calllogs/getDropCause", {
        params: { uniqueid },
      });

      console.table(kq.data.rows);
    } catch (err) {
      console.log("[getDropCause]:err=", err);
    }
  };
  const rdTime = (item) => {
    let kq = <span></span>;
    switch (item.calldirection) {
      case "in":
        if (item.connected == "0") {
          kq = (
            <>
              <div>[{item.calltype}]</div>
            </>
          );
        }
        break;

      default:
        break;
    }

    return kq;
  };
  const rdAgent = (item) => {
    let kq = <span></span>;
    switch (item.calldirection) {
      case "in":
        if (item.ext != "") {
          if (item.connected == "1") {
            kq = (
              <>
                {item.ext} - {item.agentname}
                <div title="Agent CallType">[{item.calltypeagent}]</div>
                <div>pickup:{item.pickup}</div>
              </>
            );
          } else {
            kq = (
              <>
                {item.ext} - {item.agentname}
                <div title="Agent CallType">[{item.calltypeagent}]</div>
              </>
            );
          }
        } else {
          if (item.forwardnumber != "") {
            kq = <>{item.forwardnumber}</>;
          }
        }

        break;
      case "pbx":
      case "out":
        kq = (
          <>
            {item.ext} - {item.agentname}
          </>
        );
        break;
      default:
        break;
    }

    return kq;
  };
  const rdTalkTime = (item) => {
    let kq = <span></span>;
    if (item.connected == "1") {
      switch (item.calldirection) {
        case "in":
          kq = <div>{formatTime(item.talktime)}</div>;
          if (item.command == "Dial") {
            kq = <div>{formatTime(item.billsec)} </div>;
          }
          break;

        default:
          kq = <div>{formatTime(item.billsec)}</div>;

          break;
      }
    }

    return kq;
  };
  const handleSapXep=(e)=>{
    e.preventDefault();
    if(sapxep==="DESC"){
      setSapxep("ASC");
    }else{
      setSapxep("DESC");
    }
  }
  return (
    <>
      <Drawer
        title="AddItem"
        width={640}
        placement="right"
        onClose={onCloseAddAddContact}
        visible={visibleNewContact}
      >
        {visibleNewContact && selectedItem && (
          <AddNewContact
            setRefressSearch={setRefressSearch}
            setVisibleAdd={setVisibleNewContact}
            ctel={selectedItem.tel}
          />
        )}
      </Drawer>

      <Drawer
        width={640}
        title="Edit Item"
        placement="right"
        onClose={onCloseEditContact}
        visible={visibleEditContact}
      >
        {contactInfo && (
          <EditContact
            item={contactInfo}
            setSelectedItem={setContactInfo}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEditContact}
            uniqueid={selectedItem.uniqueid}
            tkid={selectedItem.tkid}
          />
        )}
      </Drawer>

      {visibleSwitch && (
        <Drawer
          width={1040}
          title="Please select a customer !"
          placement="right"
          visible={visibleSwitch}
          onClose={onCloseSwitch}
        >
          <SwitchCustomer
            setCustomerid={setCustomerid}
            setVisibleSwitch={setVisibleSwitch}
            ctel={selectedItem.tel}
            uniqueid={selectedItem.uniqueid}
            setRefressSearchCallLog={setRefressSearch}
          />
        </Drawer>
      )}

      <div className="searchResult">
        {visibleEdit && selectedItem && (
          <TicketNoteOutEditByTKID
            tkid={selectedItem.tkid}
            item={selectedItem}
            setRefressSearch={setRefressSearch}
            setSelectedItem={setSelectedItem}
            setVisibleEdit={setVisibleEdit}
          />
        )}
        {visibleNewTicket && selectedItem && (
          <TicketNoteNewFromCallLog
            setSelectedItem={setSelectedItem}
            item={selectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleNewTicket={setVisibleNewTicket}
          />
        )}

        <Typography.Title level={3}>Call Logs</Typography.Title>

        <table className="table1">
          <thead>
            <tr>
              {/* <th>id</th> */}
              {/* <th>calldirection</th>
            <th>connected</th> */}
              <th>No.</th>
              <th>
                <InfoCircleOutlined title="Call type" />
              </th>
              <th width="250px"><div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>Time
                <div> <ArrowUpOutlined style={{cursor:"pointer","color":(sapxep==="ASC")&&"red"}} title="Tăng dần" onClick={()=>setSapxep("ASC")} />
                      <ArrowDownOutlined style={{cursor:"pointer","color":(sapxep==="DESC")&&"red"}} title="Giảm dần" onClick={()=>setSapxep("DESC")} /></div>
                </div>
              </th>
              <th>Department</th>
              <th width="220px">Tel</th>
              <th width="220px">Agent</th>

              <th>Talktime</th>
              {/* <th>duration</th> */}
              <th>Record file</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                {/* <td>{item.id}</td> */}
                {/* <td>{item.calldirection}</td>
            <td>{item.connected}</td> */}
                <td>{stt++}</td>
                <td>{RenderCallInfo(item)}</td>
                <td>
                  <div>
                    <span className="labelTime">start:</span>{" "}
                    {moment(item.start).format(dateFormat)}{" "}
                  </div>
                  <div>
                    <span className="labelTime">end:</span>{" "}
                    {moment(item.end).format(dateFormat)}
                  </div>

                  <div>{RenderCallStatus(item)}</div>
                  {rdTime(item)}
                  {/* <div onClick={() => getDropCause(item.uniqueid)}>
                    {item.uniqueid}
                  </div> */}
                  {/* <div title="QueueCalltype">[{item.calltype}]</div> */}

                  {/* <div>status:{item.agentlist}</div>
                  <div>List:{item.agentlistseq}</div>
                  <div>id:{item.id}</div> */}
                </td>
                <td>
                  {getDepartmentName(item.department_id)} <br />[{item.pbxtel}]
                  <br /> {item.ivrdongy != "" && "ivrdongy  : " + item.ivrdongy}
                </td>
                <td>
                  <span
                    className="stitle"
                    title="Customer name | Click here to Add/Edit Customer"
                    onClick={() => handleCustomer(item)}
                  >
                    <UserOutlined className={"icon"} />
                  </span>
                  <span className="svalue">{item.customer_name}</span>
                  <br />
                  <span
                    className="stitle"
                    title="Company  | Click here to Add/Edit Customer"
                    onClick={() => handleCustomer(item)}
                  >
                    <HomeOutlined className={"icon"} />
                  </span>
                  <span className="svalue">{item.customer_company}</span>
                  <br />
                  <span
                    className="stitle"
                    title="Tel, Click here to make a call!"
                    onClick={() => handleMakeCall(item.tel)}
                  >
                    <PhoneOutlined className={"icon"} />
                  </span>
                  <span className="svalue">{item.tel}</span>
                  <br />
                  <span
                    className="stitle"
                    title="Ticket Status  | Click here to Add/Edit Ticket"
                    onClick={() => handleEditTicket(item)}
                  >
                    <FlagOutlined className={"icon"} />
                  </span>
                  <span className="svalue">{item.tkstatus}</span>
                  <br />
                  <span
                    className="stitle"
                    title="Ticket Title | Click here to Add/Edit Ticket "
                    onClick={() => handleEditTicket(item)}
                  >
                    <FlagOutlined className={"icon"} />
                  </span>
                  <span className="svalue">{item.tktitle}</span>
                  <br />
                </td>
                <td>
                  {rdAgent(item)}
                  {/* <div title="Agent CallType">[{item.calltypeagent}]</div>
                  <div>pickup:{item.pickup}</div> */}
                </td>

                <td>
                  {rdTalkTime(item)}
                  {/* {formatTime(item.billsec)}
                  <div>{formatTime(item.talktime)}</div> */}
                </td>
                {/* <td>
                {item.duration}
            </td> */}
                <td>
                  {/* { item.connected ||item.vm==="true"?(<Button type="link" onClick={()=>handlePlayAudio(item.id,item.uniqueid)}><StepForwardOutlined />Play</Button>):""}
            { item.connected||item.vm==="true"?<Button type="link" onClick={()=>handleDownloadAudio(item.id,item.uniqueid,item.pbxtel,item.tel,item.ext)}><DownloadOutlined />Download</Button>:""} */}

                  {item.calldirection === "out" ||
                  item.connected ||
                  item.vm === "true" ? (
                    <Button
                      type="link"
                      onClick={() => handlePlayAudio(item.id, item.uniqueid)}
                    >
                      <StepForwardOutlined />
                      Play
                    </Button>
                  ) : (
                    <span></span>
                  )}
                  {item.calldirection === "out" ||
                  item.connected ||
                  item.vm === "true" ? (
                    <Button
                      type="link"
                      onClick={() =>
                        handleDownloadAudio(
                          item.id,
                          item.uniqueid,
                          item.pbxtel,
                          item.tel,
                          item.ext
                        )
                      }
                    >
                      <DownloadOutlined />
                      Download
                    </Button>
                  ) : (
                    <span></span>
                  )}
                  {/* <div>[{item.uniqueid}]</div> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="callLogAudio" style={{ display: displayAudio }}>
          <audio ref={refAudio} controls>
            <source src={""} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
          <Button type="link" onClick={() => setDisplayAudio("none")}>
            Close Audio
          </Button>
        </div>
      </div>
    </>
  );
}
export default ShowCallLogTable;
