import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import { configureStore } from "@reduxjs/toolkit";
import jsSIPReducer from "./jsSIPSlice";
import loginSlice from "./loginSlice";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    jsSIPReducer: jsSIPReducer,
    loginReducer: loginSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => customizedMiddleware,
});
