import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  DatePicker,
  TimePicker,
  Select,
  Input,
  Space,
  notification,
} from "antd";
// import MyChartLine from "./MyChartLine";
import MyChartColumn from "./MyChartColumn";
import CallInAndAbandonedColumnChart from "./CallInAndAbandonedColumnChart";
import DashboardDisplay from "./DashboardDisplay";
import AbandonedRatioColumnChart from "./AbandonedRatioColumnChart";
import ShowAgentSummary from "./ShowAgentSummary";
import useAxios from "./../../CustomHooks/useAxios";

import DebounceSelect from "./../DebounceSelect";
const style = {
  //  background: 'rgb(178, 227, 156) none repeat scroll 0% 0%',
  display: "flex",
  flexDirection: "column",
  padding: "8px 0",
};
const url = "https://gcall.com.vn:2943";
const instance = axios.create({
  baseURL: url,
  withCredentials: true,
});

const { Text } = Typography;
// const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";
export default function Dashboard() {

  const { axiosi } = useAxios();
  const [data, setData] = useState();
  const [dataSum, setDataSum] = useState();
  const [txtStatus, setTxtStatus] = useState(
    "Please click the search button to get the data !"
  );

  const user = useSelector((state) => state.loginReducer.user);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [sFrom, setSFrom] = useState(
    moment(moment().format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );
  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState("");

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type,
    message,
    description,
    duration,
    placement
  ) => {
    api[type]({ message, description, duration, placement });
  };

  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        // setDepartmentSelected("");
        setDepartmentSelected(user && user?.agents[0].log_agent_status_deps);

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);

  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
    // setExten(null);
    // setRefreshOptionExten((pre) => !pre);
  };
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;
    // console.log("[checkLogDep]:logdeps=",logdeps);
    // console.log("[checkLogDep]:item.id=",item.id);
    // console.log("[checkLogDep]:logdeps.includes(item.id)=",logdeps.includes(item.id));
    return logdeps.includes(item.id);
  }
  async function fetchCompanyList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  useEffect(() => {
    // const kq= await axios.get(`${url}/calllog`,{withCredentials:true});
    async function fetchData() {
      // You can await here
      // const kq= await instance.post(`rt`);
      const kq = await axiosi.post("dashboard");
      console.log(kq.data);
      // ...
    }

    fetchData();
  }, []);
  const handleTestAPI406 = async () => {
    console.log("[handleTestAPI]");
    try {
      const kq = await axios.get(`${url}/406`, {
        responseType: "blob",
        withCredentials: true,
      });
      console.log("[kq]:", kq);
      console.log("[kq.data]:", kq.data);
    } catch (error) {
      // console.log ("[catch]: Error =",error)
      console.log("error.response.data=", error.response.data);

      //     var enc = new TextDecoder('utf-8')
      //   var res =  JSON.parse(enc.decode (new Uint8Array( error.response.data )));// convert to JSON object
      //   console.log("[handleTestAPI-error]:",res)

      // var b = new Blob(error.response.data);
      var b = error.response.data;
      var fr = new FileReader();
      // fr.onload = function() {
      //     console.log(JSON.parse(this.result))
      // };
      fr.onload = (e) => {
        console.log("kq readtext=", JSON.parse(e.target.result));
      };
      console.log("error.response.data File Read=", fr.readAsText(b));

      console.log("error.response.status=", error.response.status);
      console.log("error.response.headers=", error.response.headers);
    }
  };
  const handleTestAPI = async () => {
    console.log("[handleTestAPI]");
    try {
      const kq = await axios.get(`${url}/203`, {
        responseType: "blob",
        withCredentials: true,
      });
      console.log("[kq]:", kq);
      console.log("[kq.data]:", kq.data);
      console.log("[kq.data.text()]:", await kq.data.text());
      // console.log("[kq.data.Symbol()]:",await kq.data.Symbol(Symbol.toStringTag));
    } catch (error) {
      // console.log ("[catch]: Error =",error)
      console.log("error.response.data=", error.response.data);

      //     var enc = new TextDecoder('utf-8')
      //   var res =  JSON.parse(enc.decode (new Uint8Array( error.response.data )));// convert to JSON object
      //   console.log("[handleTestAPI-error]:",res)

      // var b = new Blob(error.response.data);
      var b = error.response.data;
      var fr = new FileReader();
      // fr.onload = function() {
      //     console.log(JSON.parse(this.result))
      // };
      fr.onload = (e) => {
        console.log("kq readtext=", JSON.parse(e.target.result));
      };
      console.log("error.response.data File Read=", fr.readAsText(b));

      console.log("error.response.status=", error.response.status);
      console.log("error.response.headers=", error.response.headers);
    }
  };
  function onChangeSFrom(date, dateString) {
    setSFrom(dateString);
  }
  function onChangeSTo(date, dateString) {
    setSTo(dateString);
  }
  const handleSearchCallLogSum = async () => {
    try {
      // setPage(1);
      
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const sdata = {
        page: 1,
        timeFrom,
        timeTo,
        pickupSecond:process.env.REACT_APP_PICKUP_SECOND,
        abc:1,
        // scallStatus,
        // scalltype,
        // sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        // stel,
        // timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        // timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
      };
      console.log(sdata);
      // openNotificationWithIcon(
      //   "success",
      //   "Getting data",
      //   "Upload data is successfully!",
      //   2,
      //   "bottomRight"
      // );
      setDataSum(null);
      setTxtStatus("Getting data ...");
      const kq = await axiosi.get("calllogs/getCallLogsSumDashBoard1", {
        params: sdata,
      });
      console.log(kq);

      const kqdata = await kq.data; //agentCallLogsSum

      console.log({ kqdata });
      // return;
      if (kq.status !== 204) {
        setDataSum(kq.data);
        setData(null);
        console.log("[handleSearchCallLogSum]:", kqdata);
        setTxtStatus("");
      } else {
        console.log("[handleSearchCallLogSum-No Content] kq.data=", kq.data);
        setDataSum(null);
        setData(null);
        setTxtStatus("There a no data !");
      }
    } catch (error) {
      console.log("[handleSearchCallLogSum]:", error);
      setDataSum(null);
      setData(null);
      setTxtStatus("There a no data ...!");
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  const btnSearchOnClick = () => {
    console.log("[btnSearchOnClick]");
    handleSearchCallLogSum();
    // if (page === 1) {
    //   console.log("[btnSearchOnClick page=1] : set setRefressSearch:");
    //   setRefressSearch((pre) => !pre);
    // } else {
    //   console.log("[btnSearchOnClick page!=1] : set setpage=1:");
    //   setPage(1);
    // }
  };
  return (
    <>
      {contextHolder}
      {console.log("callLogsSumALl=dataSum :", dataSum)}

      <Typography.Title level={3}>Search :</Typography.Title>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}>
          <div style={style}>
            <Text>From :</Text>
            <DatePicker
              // showTime
              onChange={onChangeSFrom}
              // onSelect={onSelectSFrom}
              // onClear={onClearSFrom}
              // value={moment(sFrom,dateFormat1)}
              defaultValue={sFrom}
              format={dateFormat}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={6}>
          <div style={style}>
            <Text>To :</Text>
            <DatePicker
              //showTime
              onChange={onChangeSTo}
              defaultValue={sTo}
              format={dateFormat}
            />
          </div>
        </Col>

        <Col className={`gutter-row ${hideItem} `} span={4}>
          <div style={style}>
            <Text>Company :</Text>
            <DebounceSelect
              showSearch={true}
              value={companyid}
              placeholder=""
              fetchOptions={fetchCompanyList}
              onChange={(newValue) => {
                setCompanyid(newValue);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>Department :</Text>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              onChange={handleOnChangeDepartment}
              value={departmentSelected}
            >
              <Option value=""></Option>
              {departmentData &&
                departmentData.map((item) => {
                  if (checkLogDep(item)) {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  }
                })}
            </Select>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text> &nbsp;</Text>

            <Button onClick={btnSearchOnClick}>Search</Button>
          </div>
        </Col>
      </Row>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text> &nbsp;</Text>

            <Button onClick={btnSearchOnClick}>Search</Button>
          </div>
        </Col>
      </Row> */}
      {!dataSum && txtStatus}
      {dataSum && (
        <DashboardDisplay
          callLogsSumALl={
            dataSum?.callLogsSumALl
              ? dataSum.callLogsSumALl.length > 0
                ? dataSum.callLogsSumALl[0]
                : null
              : null
          }
        />
      )}
      {/* <div className="dashBoard_flex-container">
        <MyChartColumnGroup />
      </div> */}
      {dataSum && (
        <div className="dashBoard_flex-container">
          <CallInAndAbandonedColumnChart
            sumAbandoned={
              dataSum?.sumAbandoned
                ? dataSum.sumAbandoned.length > 0
                  ? dataSum.sumAbandoned
                  : null
                : null
            }
          />
        </div>
      )}
      {dataSum && <ShowAgentSummary data={dataSum} />}
      {/* <div>
        <h3>Dashboard</h3>
        <button onClick={handleTestAPI}>Test API</button>
      </div> */}
    </>
  );
}
