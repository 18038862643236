import React,{useState,useEffect} from 'react'
import axios from "axios";
const GetIP = () => {
  const [curIP, setCurIP] = useState("");
  const [preIP, setPreIP] = useState("");
  useEffect(() => {
    // function getIP() {
    //   fetch(`${process.env.REACT_APP_API_URL}/ip`)
    //     .then((response) => {
    //       // console.log("response=", response);
    //       return response.json();
    //     })
    //     .then((data) => setCurIP(data.ip));
    // }
    function getIP() {
      axios.get(`${process.env.REACT_APP_API_URL}/ip`)
        .then((response) => {
          console.log("response=", response.data.ip);
          let newIP=response.data.ip;
          console.log({preIP,curIP,newIP});
          
          if (curIP !== response.data.ip) {
            setPreIP(curIP);
          }
          setCurIP(response.data.ip);
          
        })        
    }
    getIP();
    let timer = setInterval(getIP, 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  
  return (
    <><div>curIP : {curIP}</div>
    {(preIP) &&<div>prevIP : {preIP}</div>}
    </>
  )
}

export default GetIP